"use client";

import { useTranslations } from "@packages/i18n";
import type { PaymentResult } from "@packages/sdk";
import { Button, Form, FormField, TextInput, useAlert } from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import * as stylex from "@stylexjs/stylex";
import { useForm } from "react-hook-form";

import type { CheckoutProps } from "./Checkout";
import { PaypalElement } from "./PaypalElement";
import { StripePaymentElement } from "./StripePaymentElement";

const styles = stylex.create({
  base: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: spacing.s,
    width: spacing.full,
  },
  submit: {
    marginTop: spacing.m,
  },
});

export const CheckoutForm = ({
  platforms = ["stripe", "paypal"],
  ...props
}: CheckoutProps) => {
  const t = useTranslations();
  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert();

  const onConfirm = async ({ email, name }: Partial<PaymentResult>) => {
    const { error: submitError } = await elements.submit();
    if (submitError) {
      alert.show({
        title: t("general_word_error"),
        description: submitError.message,
      });
      return;
    }

    const { paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email,
          name,
        },
      },
    });
    const { address } = paymentMethod.billing_details;
    props.onSubmit({
      paymentMethod: paymentMethod.id,
      paymentPlatform: "stripe",
      name,
      email,
      address,
    });
  };

  const form = useForm<Partial<PaymentResult>>();
  const {
    formState: { isDirty, isValid, isSubmitting },
  } = form;
  return (
    <Form
      formProps={{
        onSubmit: form.handleSubmit(onConfirm),
        styleXArray: [styles.base],
      }}
      {...form}
    >
      {platforms.includes("stripe") && (
        <>
          {props.requireName && (
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <TextInput
                  required
                  placeholder={t("user_payment_cardholder_placeholder")}
                  {...field}
                />
              )}
            />
          )}
          {props.requireEmail && (
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <TextInput
                  type="email"
                  required
                  placeholder={t("general_email")}
                  {...field}
                />
              )}
            />
          )}
          <StripePaymentElement form={form} onSubmit={onConfirm} />
        </>
      )}

      {platforms.includes("paypal") && <PaypalElement {...props} />}

      <Button
        type="submit"
        disabled={!isDirty || !isValid || isSubmitting}
        size="l"
        isFullWidth={true}
        styleXArray={[styles.submit]}
      >
        {props.buttonLabel}
      </Button>
    </Form>
  );
};
