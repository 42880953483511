"use client";

import { HallowAnalyticsEvent } from "@packages/analytics";
import { usePathMatch } from "@packages/sdk";
import type { SidebarProps, SidebarTabProps } from "@packages/ui";
import {
  ActivityIcon,
  CommunitySelector,
  FriendsIcon,
  ReportedCounter,
  ReportIcon,
  SettingsAltIcon,
  Sidebar,
  SidebarTab,
  UpwardTrendIcon,
} from "@packages/ui";
import { useParams } from "next/navigation";

export type ParishAdminDashboardSidebarProps = Omit<
  SidebarProps,
  "children" | "state"
>;

export const ParishAdminDashboardSidebar = ({
  ...props
}: ParishAdminDashboardSidebarProps) => {
  const params = useParams<{ communityId: string }>();
  const matcher = usePathMatch();

  const parishAdminBaseUrl = `/admin/${params.communityId}`;

  const parishAdminTabs: (Pick<
    SidebarTabProps,
    "actionRight" | "icon" | "message"
  > & {
    sidebarState: SidebarProps["state"];
    path: string;
  })[] = [
    {
      icon: <UpwardTrendIcon />,
      message: "web_dashboard",
      path: "dashboard",
      sidebarState: "expanded",
    },
    {
      icon: <ActivityIcon />,
      message: "intentions_activity",
      path: "activity",
      sidebarState: "expanded",
    },
    {
      icon: <FriendsIcon />,
      message: "community_settings_members",
      path: "members",
      sidebarState: "closed",
    },
    {
      actionRight: (
        <ReportedCounter communityId={parseInt(params.communityId)} />
      ),
      icon: <ReportIcon />,
      message: "parish_admin_dashboard_navigation_reported",
      path: "reported",
      sidebarState: "expanded",
    },
    {
      icon: <SettingsAltIcon />,
      message: "settings_title",
      path: "settings",
      sidebarState: "closed",
    },
  ];

  const activeTab = parishAdminTabs.find((tab) =>
    matcher(`${parishAdminBaseUrl}/${tab.path}`),
  );
  const activeTabSidebarState = activeTab?.sidebarState ?? "expanded";

  return (
    <Sidebar state={activeTabSidebarState} {...props}>
      <CommunitySelector triggerState={activeTabSidebarState} />
      {parishAdminTabs.map(({ sidebarState, path, ...props }) => (
        <SidebarTab
          key={path}
          href={`${parishAdminBaseUrl}/${path}`}
          state={activeTabSidebarState}
          analyticsEvent={{
            event: HallowAnalyticsEvent.TappedAdminDashboardTab,
            properties: {
              tab: path,
            },
          }}
          {...props}
        />
      ))}
    </Sidebar>
  );
};
