"use client";

import { I18nMessageClient, useTranslations } from "@packages/i18n";
import { URL_BASE } from "@packages/sdk";
import { Button, IconButton, InfoIcon, Text, useConfirm } from "@packages/ui";
import { semanticColors } from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";

const styles = stylex.create({
  primaryButton: {
    color: semanticColors.accentMedium,
  },
  reportedForHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
});

export const ReportedGuidelinesDialog = () => {
  const t = useTranslations();

  const dialogReportGuidelines = useConfirm({
    description: () => t("pad_reported_description"),
    primaryButton: () => (
      <Button asChild styleXArray={[styles.primaryButton]} variant="outline">
        <Link href={`${URL_BASE}/community-guidelines`} target="_blank">
          {t("dialog_report_guidelines_button")}
        </Link>
      </Button>
    ),
    showCloseButton: true,
    size: "l",
    title: () => t("pad_review_reported_posts"),
  });

  return (
    <div {...stylex.props(styles.reportedForHeader)}>
      <Text size="l" type="title" as="h2">
        <I18nMessageClient message="parish_admin_dashboard_moderation_reported_posts" />
      </Text>
      <IconButton
        onClick={async () => await dialogReportGuidelines.show()}
        icon={<InfoIcon />}
        variant="transparentNeutral"
      />
    </div>
  );
};
