import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { getFullName, type User, useRequestMe } from "@packages/sdk";
import type { HallowElementProps, WithStylexArray } from "@packages/ui";
import { Avatar, ListProfile } from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";

import { EditMemberDropdownMenu } from "../EditMemberDropdownMenu";

const styles = stylex.create({
  base: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
  },
  listProfilePlaceHolder: {
    height: 40,
    width: 40,
  },
  memberContainer: {
    position: "relative",
  },
  memberIconButton: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export type ParishAdminDashboardMembersProps = WithStylexArray<
  HallowElementProps<"div">
> & {
  communityId: number;
  loading?: boolean;
  loadingSkeletonsTotal?: number;
  members: User[];
  totalNumberOfAdmins: number;
};

export const ParishAdminDashboardMembers = ({
  communityId,
  loading = false,
  loadingSkeletonsTotal = 3,
  members,
  totalNumberOfAdmins,
  styleXArray,
  ...props
}: ParishAdminDashboardMembersProps) => {
  const { query: meQuery } = useRequestMe();
  const analytics = useAnalytics();

  if (loading || !members || meQuery.isLoading)
    return (
      <div {...props} {...stylex.props(styles.base)}>
        {Array.from(Array(loadingSkeletonsTotal).keys()).map((i) => (
          <ListProfile key={i} loading title="" />
        ))}
      </div>
    );

  return (
    <div {...props} {...stylex.props(styles.base)}>
      {members.map((member) => (
        <div
          key={member.id}
          {...stylex.props(styles.memberContainer, styleXArray)}
        >
          <ListProfile
            asChild
            avatar={
              <Avatar
                key="avatar"
                imgSrc={member?.image_url}
                name={member?.name}
                size={64}
              />
            }
            description={member?.username}
            icon={<div {...stylex.props(styles.listProfilePlaceHolder)} />}
            title={getFullName({
              firstName: member?.name,
              lastName: member?.last_name,
            })}
            onClick={() => {
              analytics.track({
                event: HallowAnalyticsEvent.TappedCommunityMemberProfile,
                properties: { platform: "web" },
              });
            }}
          >
            <Link href={`/admin/${communityId}/members/${member.id}`} />
          </ListProfile>
          <EditMemberDropdownMenu
            communityId={communityId}
            user={{
              ...member,
              is_self: member.id === meQuery.data?.id,
              is_only_admin: member.is_admin && totalNumberOfAdmins === 1,
            }}
            triggerStyleXArray={[styles.memberIconButton]}
          />
        </div>
      ))}
    </div>
  );
};
