"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import type { CommunityActivityFeedReferenceType } from "@packages/sdk";
import { useRequestCommunitiesAdminPost } from "@packages/sdk";
import {
  DropdownMenu,
  DropdownMenuItem,
  IconButton,
  MoreIcon,
  TrashIcon,
  useAlert,
} from "@packages/ui";
import {
  dropShadow,
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import { useParams } from "next/navigation";

import { useConfirmRemovePostDialog } from "../../useConfirmRemovePostDialog";

const styles = stylex.create({
  dropdownMenuItem: {
    columnGap: spacing.s,
    minWidth: "180px",
    padding: `${spacing.xs} ${spacing.s}`,
  },
  dropdownMenu: {
    boxShadow: `${dropShadow.none} ${dropShadow.ms} ${dropShadow.m} ${dropShadow.none} ${semanticColors.fixedDarkenVeryLow}`,
  },
  dropdownMenuItemError: {
    color: {
      default: semanticColors.errorMedium,
      ":hover": semanticColors.errorMedium,
      ":focus": semanticColors.errorMedium,
      ":active": semanticColors.errorMedium,
    },
  },
});

type RemovePostDropdownMenuProps = {
  communityPostId: number;
  type?: CommunityActivityFeedReferenceType;
};

export const RemovePostDropdownMenu = ({
  communityPostId,
  type,
}: RemovePostDropdownMenuProps) => {
  const t = useTranslations();
  const { communityId } = useParams<{ communityId: string }>();
  const alert = useAlert();
  const analytics = useAnalytics();

  const { mutationDelete: deletePost } = useRequestCommunitiesAdminPost({
    id: parseInt(communityId),
  });

  const dialogDelete = useConfirmRemovePostDialog();

  const handleDelete = async () => {
    analytics.track({
      event: HallowAnalyticsEvent.TappedRemovePost,
      properties: { platform: "web" },
    });

    const confirm = await dialogDelete.show();
    if (!confirm) return;

    try {
      await deletePost.mutateAsync({
        communityPostId,
        type,
      });

      analytics.track({
        event: HallowAnalyticsEvent.RemovedPost,
        properties: {
          button: "remove",
          is_admin: true,
          platform: "web",
          type,
        },
      });
    } catch (error) {
      const message = await error.json();
      alert.show({
        title: t("general_word_error"),
        description: message.error,
      });
    }
  };

  return (
    <DropdownMenu
      trigger={<IconButton icon={<MoreIcon />} variant="transparentNeutral" />}
      contentStyleXArray={[styles.dropdownMenu]}
      align="end"
      contentProps={{ collisionPadding: 20, sideOffset: 5 }}
    >
      <DropdownMenuItem
        onClick={handleDelete}
        style={styles.dropdownMenuItem}
        startIcon={<TrashIcon />}
        styleXArray={[styles.dropdownMenuItem, styles.dropdownMenuItemError]}
      >
        {t("community_remove_post_action")}
      </DropdownMenuItem>
    </DropdownMenu>
  );
};

RemovePostDropdownMenu.displayName = "ActivityCardPromptResponse";
