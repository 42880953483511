"use client";

import type { PaymentResult } from "@packages/sdk";
import { PaymentElement } from "@stripe/react-stripe-js";
import type { UseFormReturn } from "react-hook-form";

type StripePaymentProps = {
  form: UseFormReturn<Partial<PaymentResult>, any, undefined>;
  onSubmit: (params: Partial<PaymentResult>) => void;
};

export const StripePaymentElement = ({
  form,
  onSubmit,
}: StripePaymentProps) => {
  const email = form.watch("email");
  const name = form.watch("name");

  return (
    <PaymentElement
      options={{
        layout: {
          type: "accordion",
          defaultCollapsed: true,
          radios: false,
          spacedAccordionItems: true,
        },
        defaultValues: {
          billingDetails: {
            email,
            name,
          },
        },
      }}
      onChange={(event) => {
        if (
          event.value.type === "apple_pay" ||
          event.value.type === "google_pay"
        ) {
          onSubmit({ email, name });
        }
      }}
    />
  );
};
